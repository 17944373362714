<template>
  <div >
    <select id="select" v-if="header.type === 'select' || header.type === 'select-number'" 
    v-model="item[header.key]"
      :disabled="header.disabled"
      clearable
      :placeholder="header.key"
      @change="itemChanged()">
      <option disabled value=''>{{ header.key }}</option>
      <option  v-for = "ref in filterRef(refTables(header.optionstable),header.filterField, header.filterValue)"
      :key="ref.code" 
      v-bind:value="ref.code">{{  ref.title }}</option>
     {{ item[header.key] }} 
    </select> 
   
  <input v-else-if="header.type==='boolean'" type="checkbox" v-model="item[header.key]" :placeholder="header.key" @blur= "itemChanged()" @keyup.tab="itemChanged()" @keyup.enter="itemChanged()" /> 
  <input v-else-if="header.type==='number'" type="number" v-model="item[header.key]"  :placeholder="header.key" @blur= "itemChanged()" @keyup.tab="itemChanged()" @keyup.enter="itemChanged()" /> 
  <Datepicker v-else-if="header.type==='date'" v-model="item[header.key]" 
        format="yyyy-MM-dd hh:mm"
        :enable-time-picker="false"
         :placeholder="header.key"
        required
        clearable
        @change="itemChanged()"
></Datepicker>
  <Datepicker v-else-if="header.type==='datetime'" v-model="item[header.key]" 
   :placeholder="header.key"
  format="yyyy-MM-dd hh:mm"
        :enable-time-picker="true"
        required
        clearable
        @change="itemChanged()"></Datepicker>
        <div v-else-if="header.type==='barcode'">
        <input 
        v-model="item[header.key]"
        :label="header.key"
        :placeholder="header.key"
        @blur= "itemChanged()" @keyup.tab="itemChanged()" @keyup.enter="itemChanged()"
        />
        <v-btn 
          @click="scan = true"
          size="x-small"
        >
        <v-icon >mdi-barcode</v-icon>
        </v-btn>
       <v-dialog content-class='my-custom-dialog' width="600" height="100"
        v-model="scan" 
          >
      <ScanBarcode v-on="handler" :field="header.value" > </ScanBarcode>
      </v-dialog>
      </div>  
<input v-else type="text" v-model="item[header.key]"  :placeholder="header.key" @blur= "itemChanged()" @keyup.tab="itemChanged()" @keyup.enter="itemChanged()"/>
        <v-tooltip
              activator="parent"
              location="bottom"
            >{{ header.key }}</v-tooltip>
      </div> 
  
  </template>

    <script>
    import support from '../plugins/support'
    import  Datepicker  from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';
    import ScanBarcode from '@/components/ScanBarcode.vue'
      export default { 
       components: {
        ScanBarcode,
        Datepicker
      },
        props:{
            header:Object,
            value:Object,
        },

        data(){
            return{
                item: this.value,
                scan: false,
                handler:{
                  closeScanner: this.close_scanner,
                  barCodeInput: this.handleBarcode,
                },
                original:{}
            }
        },
        created(){
          this.original = this.value;
          this.$watch(
            'value',() => {
              this.item = this.value})
        },
    methods: {
      itemChanged() {
        console.log(this.item)
        if(this.item[this.header.value] !== this.original[this.header.value]){ //item has actually changed
          this.$emit("item_changed", this.item)
          this.original = this.item //update original so we know when it is changed next time.
        }
        
     },
     handleBarcode(info) {
      this.item[info.field] = info.value;
      this.$emit('item_changed', this.item)
     },
     close_scanner(item) {
      item
      this.scan=false
     },
      filterRef(valueArray,filterField,filterValue){
        if(valueArray !== undefined){
    if (filterField && filterValue){
      return valueArray.filter((el) =>
        el[filterField] === filterValue)
    }
    else{
      return valueArray
    }
  } else {
    return []
  }
  },
      refTables(tablename){
          return this.$store.getters['data/refTable'](tablename);
        },
      save () {
        
      },
      cancel () {
        //don't do anything on cancel
      },
      open () {

      },
      close () {
        console.log('Dialog closed')
        console.log(this.item)
        this.$emit('closed',this.item)
      },
       lookup(item,header){
            return support.lookup(item,header)
          },
      },

      }
    </script>
    <style>

  </style>
    