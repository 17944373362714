import data from './data'
import axios from "axios";
import qs from "qs";
import localforage from "localforage";
import support from '../../plugins/support';
import packageJson from '../../../package.json'

axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT_URL;
// axios.defaults.headers.post["Content-Type"] =
//   "application/x-www-form-urlencoded";
axios.defaults.withCredentials = true;
// initial state
// shape: [{ id, quantity }]
const state = () => ({
    version: packageJson.version,
    //dbName: null,
    dbName: process.env.VUE_APP_DATABASE,
    authDb: "ydfda",
    deviceCode: null,
    coordinates: null,
    gpsOn: false,
    status: "",
    username: "ydfda",
    loggedIn: false,
    unsavedData: false,
    loginDuration: 43200, //12 hours
    dataDuration: 129600, //36 hours
  })
  
  // getters
  const getters = {
    authDb: (state) => state.authDb,
    dbName: (state) => state.dbName,
    deviceCode: (state) => state.deviceCode,
    authStatus: (state) => state.status,
    username: (state) => state.username,
    isLoggedIn: (state) => state.loggedIn,
    version: (state) => state.version,
    locationOn: (state) => state.coordinates != null,
    baseUrl: (state) => state.baseUrl,
    coordinates: (state) => state.coordinates
    }
  
  // state mutations
  const mutations = {
    setUnsavedData(state, value) {
      state.unsavedData = value;
    },
    setDbName(state, dbName) {
      state.dbName = dbName;
    },
    setUserName(state, user) {
      state.username = user
    },
    setdeviceCode(state, deviceCode) {
      state.deviceCode = deviceCode;
    },
    auth_request(state) {
      state.status = "loading";
      state.loggedIn = false;
      state.username = "ydfda";
    },
    auth_success(state, username) {
      state.status = "success";
      state.loggedIn = true;
      state.username = username;
    },
    auth_error(state) {
      state.status = "error";
      state.loggedIn = false;
      window.$cookies.remove("auth");
    },
    logout(state) {
      support.removeUserSession(state.username)
      state.status = "";
      state.loggedIn = false;
      state.username = "ydfda";
      window.$cookies.remove("auth");
      
    },
    loggedIn(state,value) {
      state.loggedIn = value;
    },
    setCoordinates(state, coords) {
      state.coordinates = coords;
    },
    setGPSState(state, gpsState) {
      state.gpsOn = gpsState;
    },
  }

  // state actions
  const actions = {

    async setDbName({ commit }, dbName) {
      if (dbName && dbName.length > 0) {
        await localforage.setItem("dbName", dbName);
      } else {
        await localforage.getItem("dbName").then(function (value) {
          if (value) {
            dbName = value;
          }
        });
      }
      commit("setDbName", dbName);
    },
    
    async setDeviceCode({ commit }) {
      let deviceCode = null;
      await Promise.all([
        localforage.getItem("deviceCode").then(function (value) {
          if (value) {
            deviceCode = value;
          } else {
            deviceCode = "";
            let characters = "ABCDEF0123456789";
            let charactersLength = characters.length;
            for (let i = 0; i < 6; i++) {
              deviceCode += characters.charAt(
                Math.floor(Math.random() * charactersLength)
              );
            }
            localforage.setItem("deviceCode", deviceCode);
          }
        }),
      ]);
      commit("setdeviceCode", deviceCode);
    },
    async login({ commit }, user) {
      
      try {
        commit("auth_request");
        //console.log(qs.stringify(user))
        // let response = await axios.post(
        //   `${user.dbName}/login/`,
        //   qs.stringify(user)
        // );
          let response = await axios.post(
          `${this.getters['condition/authDb']}/login/`,
          qs.stringify(user)
        );
        if (response.status === 200) {
          commit("auth_success", user.username);
          support.storeUserSession(
            {userName: user.username,dbName: this.getters['condition/dbName'], lastLogin: support.getLocalTimestamp(new Date())});
        } else {
          commit("auth_error");
        }
      } catch (err) {
        console.log(`err: ${err.message}`);
          commit("auth_error");
          this.dispatch("condition/checkLocalLogin")
      }
    },
    async logout({ commit }) {
      // let dbName = null;
      // try{
      //   await localforage.getItem("dbName").then((v) => {
      //     if (v && v.length > 0) {
      //       dbName = v;
      //     }
      //   });
        localforage.removeItem('user')
        console.log(this.getters['condition/dbName']);
        await axios.post(`${this.getters['condition/dbName']}/logout/`)
        .then((response) => {
          if (response.status == 200) {
            commit("logout");
          } else {
            console.log("logout error!");
            commit("logout");
          }
        }).catch((err) => {
          console.log("logout error " + err);
          commit("logout");
        });
        
      // }catch {
      //   commit("logout")
      // }

    },
    async checkLocalLogin({ commit }) {
      await localforage.getItem("user").then((value) => {
        if(value !== null){
        if (support.getTimeDiff(new Date(value.lastLogin), new Date()) < this.state.condition.loginDuration) {
          commit('setDbName',value.dbName)
          commit('setUserName', value.userName)
          commit('loggedIn', true)
          
        }
      }
      });

    },
    async checkAuthentication({ commit }) {
      if (window.$cookies.get("auth")) {
        commit("auth_success");
      }
    },
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    modules: {
      data
    }
  }