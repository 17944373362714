import support from '@/plugins/support'
import packageJson from '../../../package.json'
// initial state
const state = () => ({
    // data from device_manager.digital_resources
    appName: 'Catch Data',
    version: packageJson.version,
    unsavedData: false,
    current_site_visit:{},
    netSetRecord:{},
    selectedStation:'',
    selectedTributary:'',
    selectedVisitDate:'',
    selectedSetNum: 0,
    lastSpecies:'CHUM',
    dataTables:{},
    refTables:{},
    currentRecords:{},  
    views:{},
})

  
  // getters
  const getters = {  
    tableFields: (state) => (tableName) => state.tableFields[tableName],
    dataCache: (state) => (tableName) => state.dataCache[tableName],
    tableData: (state) => (tableName) => state.dataTables[tableName],
    refTable: (state) => (tableName) => state.refTables[tableName],
    viewData: (state) => (tableName) => state.views[tableName],
    getCurrentRecord: (state) => (tableName) => state[`current_${tableName}`] ? state[`current_${tableName}`] : support.newRecord(tableName),
    refTables: (state) => state.refTables,
    dataTables: (state) => state.dataTables,
    appName: (state) => state.appName,
    tableRecord: (state) => (info) => state.dataTables[info.tableName][info.position],
    attribute: (state) => (varname) => state[varname]
    }
  
  // actions
  const actions = {

     }
  
  // mutations
  const mutations = {
    updateAttribute(state, payload){
      state[payload.attribute] = payload.value
      if (payload.attribute === 'current_site_visit') {
        this.commit("data/setSiteVisitRecord")
        }
      // else if (payload.attribute === 'selectedSetNum') {
      //   this.commit('data/setNetSet')
      // }
    },

    setNetSet(state) {
      //state.dataTables.catch = []
      //state.dataTables.tally = []
      //load the sets
      support.getForageRecords('net_set').then((result) => {
        let records = result.filter((el) =>
          el.station === state.current_site_visit.station &
        el.visit_date === state.current_site_visit.visit_date
        )
        state.dataTables.net_set = records
      

      state.current_net_set = state.dataTables.net_set.filter((el) =>
        el.set_num === parseInt(state.selectedSetNum)).length > 0 ? state.dataTables.net_set.filter((el) =>
        el.set_num === parseInt(state.selectedSetNum))[0] : support.newRecord('net_set')
      
      //catch and tally records
        state.current_catch = support.newRecord('catch')
      
        support.getForageRecords('catch').then((result) => {
          let records = result.filter((el) =>
          el.parent_id === state.current_net_set.global_id)
          state.dataTables.catch = records
        })

        state.current_tally = support.newRecord('tally')
        support.getForageRecords('tally').then((result) =>{
          let records = result.filter((el) =>
          el.parent_id === state.current_net_set.global_id)
          state.dataTables.tally = records
        })
      });
      },
    refreshChildTables(state) {
      //state.dataTables.catch = []
       // state.dataTables.tally = []
      support.getForageRecords('catch').then((result) => {
        let records = result.filter((el) =>
            el.station === state.current_site_visit.station &&
            el.visit_date === state.current_site_visit.visit_date)

          state.dataTables.catch = records
        }
        )
        support.getForageRecords('tally').then((result) => {
          let records = result.filter((el) =>
              el.station === state.current_site_visit.station &&
              el.visit_date === state.current_site_visit.visit_date)
  
            state.dataTables.tally = records
          }
          )
    },
    setSiteVisitRecord(state){
      support.getForageRecords('net_set').then((result) => {
        let records = result.filter((el) => 
        el.station === state.current_site_visit.station &
        el.visit_date === state.current_site_visit.visit_date
        )
        state.dataTables.net_set = records
        //state.dataTables.catch = []
        //state.dataTables.tally = []
        state.netSetRecord = state.dataTables.net_set.filter((el) =>
        el.set_num === parseInt(state.selectedSetNum)).length > 0 ? state.dataTables.net_set.filter((el) =>
        el.set_num === parseInt(state.selectedSetNum))[0] : support.newRecord('net_set')
             
        support.getForageRecords('catch').then((result) => {
        let records = result.filter((el) =>
            el.station === state.current_site_visit.station &&
            el.visit_date === state.current_site_visit.visit_date)

          state.dataTables.catch = records
        }
        )
        support.getForageRecords('tally').then((result) => {
          let records = result.filter((el) =>
              el.station === state.current_site_visit.station &&
              el.visit_date === state.current_site_visit.visit_date)
  
            state.dataTables.tally = records
          }
          )
    })

    },

    updateChildren(state, payload ) {
      state.dataTables[payload.childTable].map((item) => {
        if(item.parent_id === payload.parentID) {
          item[payload.childField] = payload.newValue
          support.writeRecordToLocalForage({tableName:payload.childTable, record:JSON.parse(JSON.stringify(item))})
        }
      })
    },
    
    refreshRecords(state, payload) {
      if (payload.tableType != null) {
        state[payload.tableType][payload.tableName] = payload.records;
      } else {
        state[payload.tableName] = payload.records;

      }
      support.syncLocalForageFromStore(payload)
    },
   
    resetPrimary(state) {
      state.current_site_visit = {}
      state.selectedStation=''
      state.selectedTributary=''
      state.selectedVisitDate=''
      state.selectedSetNum = 0
    },
  }
    
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    }