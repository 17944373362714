<template>
  <div class="text-center">
    <v-menu 
      open-on-hover
      top
      offset-y
    >
      <template v-slot:activator="{ props }"> 
        <input  type="checkbox"
         v-model="item['selected']" 
          v-bind="props"
         @click="checkbox_clicked" /> 

       </template >
 <div v-if ="item['selected'] === true">
      
        <v-btn 
          v-for="(btn, index) in btns"
          :key="index"
          @click="handleClick(btn.signal)"
        >
        <v-icon v-if="btn.icon">{{ btn.icon }}</v-icon>
         <span v-else>{{ btn.title }} </span>
        </v-btn>
      
</div>
    </v-menu> 
  </div>
</template>
<script>
  export default { 
    
    props:{
        header:Object,
        value:Object,
        refTables:Object,
        btns:Array
    },

    data(){
        
      return{
        item: this.getValue(),
        snackbar:false,
        hover:true,
      }
    },
      created(){
       this.$watch(
          'value',() => {
            this.item = this.getValue()
            },
          {deep: true}
    );
   
  },
  computed:{

  },
      methods: {
        handleClick(signal){
           this.$emit(signal,this.item)
        },
        getValue(){
          return this.value
        },
        checkbox_clicked(){
          this.$emit('record_selected',this.item)
        },
       
        open () {

        },
        close () {
          console.log('Dialog closed')
        },
        
        }
  }
</script>
    