<template>
  <v-app id="app">
    <v-app-bar app clipped-left dense color="primary" dark>
      <v-app-bar-title> {{ appName }} v{{ version }}</v-app-bar-title
      ><v-spacer></v-spacer
      ><v-app-bar-title app>
        <v-tooltip bottom close-delay="2000">
          <template v-slot:activator="{ attrs }">
            <v-btn icon to="/login" v-bind="attrs">
              <v-icon v-if="loggedIn" class="good">mdi-account</v-icon>
              <v-icon v-else class="alert">mdi-account-off</v-icon>
            </v-btn>
          </template>
          <span> Login</span>
        </v-tooltip>
        <!-- <v-tooltip bottom close-delay="2000">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon to="/help" v-on="on" v-bind="attrs">
              <v-icon>mdi-help-circle</v-icon>
            </v-btn>
          </template>
          <span> Help</span>
        </v-tooltip> -->
        <v-tooltip bottom close-delay="2000">
          <template v-slot:activator="{ attrs }">
            <v-btn :disabled="!isAuthenticated" icon v-bind="attrs" @click="sync">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span> Save and submit data</span>
        </v-tooltip>
        <v-tooltip bottom close-delay="2000">
          <template v-slot:activator="{ attrs }">
            <v-btn
              icon
              to="/"
              v-bind="attrs"
              @click="resetRecord"
            >
              <v-icon>mdi-file-plus</v-icon>
            </v-btn>
          </template>
          <span> Start New Record</span>
        </v-tooltip>
        <v-tooltip bottom close-delay="2000">
          <template v-slot:activator="{ attrs }">
            <v-btn
              icon
              v-bind="attrs"
              :disabled="!loggedIn"
              @click="downloadCurrentData"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <span> Download data</span>
        </v-tooltip>
      </v-app-bar-title>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <v-footer color="primary" app fixed dark v-if="loggedIn">
      <p class="ma-0 pa-0">Logged in as {{ username }} to database {{  this.dbName }} using {{  this.tablet }}</p>
      <v-spacer />
      <v-icon v-if="gettingLocation" class="good">mdi-map-marker</v-icon>
      <v-icon v-else class="alert">mdi-map-marker-alert</v-icon>
      <v-btn color="success" @click="logout">Logout</v-btn>
    </v-footer>
    <v-footer class="ma-0 pa-0" v-else color="warning" app fixed dark>
      <p class="ma-0 pa-0">Not logged in. Using database {{  this.dbName }} using {{  this.tablet }}</p>
      <v-spacer />
      <v-spacer />
      <v-icon v-if="gettingLocation" class="good">mdi-map-marker</v-icon>
      <v-icon v-else class="alert">mdi-map-marker-alert</v-icon>
      <!-- <v-btn color="secondary" @click="loginRedirect($router.currentRoute.path)"
        >Login</v-btn >-->
    </v-footer>
  </v-app>
</template>

<script>
//import localforage from "localforage";
//import { extendPrototype } from "localforage-startswith";
import axios from "axios";
import support from './plugins/support.js'
import store from "./store/index.js";
//const localforage = extendPrototype(localForage);

// NOTE: this is the base URL, all axios.get/post will need
// this.$store.getters.dbName prepended to route
axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT_URL;
// axios.defaults.headers.post["Content-Type"] =
//   "application/x-www-form-urlencoded";
axios.defaults.withCredentials = true;

export default {
  name: 'App',
  components: {},
 data: () => ({

 }),
  created() {
    this.$watch(
      "loggedIn",
      () => {
        console.log(this.$store.getters['condition/username']);
          this.handleLoginStateChange()
      },
      { deep: true }
    );
    this.$watch(
      "dbName",
      () => { //If the database name changes refresh the data cache
        if ((this.dbName === '') && (this.loggedIn)){ //logged in but no database set
          this.dbSelectRedirect()
        }else if ((this.dbName !== '') && (this.loggedIn)){//logged in and database is set
          support.refreshDataCache()
        }
      },
      { deep: true }
    );
    this.$store.dispatch("condition/setDeviceCode")
   },
  async mounted () {
    if (this.username === '') {
      store.dispatch("condition/checkLocalLogin")
    }
    support.initializeData();
    support.updateCurrentRefTables()
  },
  methods: {
    async downloadCurrentData() { 
      for (let tableName in store.getters['data/dataTables']) {
          support.getForageRecords(tableName)
            .then((records) => {
              let blob = new Blob([support.recordsToCSV(tableName, records)], {type: "text/csv"});
              let url = URL.createObjectURL(blob);
              let pom = document.createElement('a');
              pom.href = url
              pom.setAttribute('download',`${tableName}_${(new Date()).toDateString()}`)
              pom.click();
              }
            )
      }
    },
    resetRecord() {
      //support.syncAllLocalForageFromStore()
      support.clearStore()
      support.restoreFromLocalForage({tableName:'site_visit', holder:'dataTables'})
    },
    refresh() {
      support.refreshViews()
    },
    handleLoginStateChange() {
      support.updateCurrentRefTables()
      
    },
    loginRedirect: function (path) {
      this.$router.push({
        name: "login",
        query: { redirect: path },
      });
    },
    dataRedirect: function (path) {
      this.$router.push({
        name: "collect",
        query: { redirect: path },
      });
    },
    dbSelectRedirect: function (path) {
      this.$router.push({
        name: "login",
        query: { redirect: path },
      });
    },
    logout: function () {
      this.$store.dispatch("condition/logout");
    },
    sync() {
      this.resetRecord()
      support.submitLocalData();
      support.populateReferenceTables()
      
    },
  },
  computed: {
    gettingLocation() {
      return this.$store.getters['condition/locationOn'];
    },
    appName() {
      return this.$store.getters['data/appName'];
    },
    version() {
      return this.$store.getters['condition/version'];
    },
    username() {
      return this.$store.getters['condition/username'];
    },
    loggedIn () {
      return this.$store.getters['condition/isLoggedIn'];
    },
    dbName () {
      return this.$store.getters['condition/dbName']
    },
    tablet () {
      return this.$store.getters['condition/deviceCode']
    },
    isAuthenticated() {
      return this.$store.getters['condition/authStatus'] === 'success'
    },
    site_visit_records() {
      return this.$store.getters['data/dataTables']('site_visit')
    }
  },
}
</script>

<style>

html {
  overflow-y: auto !important;
  overflow: hidden;
  overscroll-behavior: none;
}
.v-application--wrap {
  min-height: 100% !important;
}
body {
  overscroll-behavior: contain;
}

#nav {
  padding: 30px;
  text-align: center;
}

#nav a {
  font-weight: bold;
  color: #f9fafc;
}

#nav a.router-link-exact-active {
  color: #ab42b9;
}

i.v-icon.alert {
  color: red;
}
i.v-icon.good {
  color: green;
}
</style>

