export default {
    tableFields: {
        site_visit: [
            {key:'station', label: null, type:'select', optionstable:'site'},
            {key:'visit_date', label: null, type:'date'},
            {key:'investigators', label: null, type:'text'},
            {key:'temperature_c', label: null, type:'number'},
            {key:'gear', label: null, type:'select', optionstable:'gear'},
            {key:'comment', label: null, type:'text'},
            {key:'global_id',label: null, type:null, keyfield: true},
        ],
        net_set:[ 
            {key:'station', label: null, type:'select', optionstable:'site'},
            {key:'visit_date', label: null, type:'date'},
            {key:'set_num', label: null, type:'number'},
            {key:'start_time',label:null,type:'time'},
            {key:'stop_time',label:null,type:'time'},
            {key:'start_latitude',label:null,type:'number'},
            {key:'stop_latitude',label:null,type:'number'},
            {key:'start_longitude',label:null,type:'number'},
            {key:'stop_longitude',label:null,type:'number'},
            {key:'flow_start',label:null,type:'number'},
            {key:'flow_stop',label:null,type:'number'},
            {key:'depth_start',label:null,type:'number'},
            {key:'depth_stop',label:null,type:'number'},
            {key:'gear', label: null, type:'select', optionstable:'gear'},
            {key:'comment', label: null, type:'text'},
            {key:'global_id',label: null, type:null, keyfield: true},
            {key:'parent_id',label: null, type:null},],
        tally: [
          {key:'_id', label: null, type:null},
          {key:'species', label:'Species', type:'select', optionstable:"species"},
          {key:'age', label:'Age',type:'select', optionstable:'age'},
          {key:'size_class', label: 'Size Class', type:'select', optionstable:'size_class'},
          {key:'tally', label: 'Count', type:'number'},
          {key:'comment_field',label: 'comment', type:'text'},
          {key:'global_id',label: null, type:null, keyfield: true},
          {key:'parent_id',label: null, type:null},
          {key:'set_num', label: null, type:'number'}
        ],
        catch: [
            {key:'_id', label: null,type:null},
            {key:'field_species', label:'Species', type:'select', optionstable:"species"}, 
            {key:'age', label:'Age',type:'select', optionstable:'age'},
            {key:'length', label: 'Length', type:'number'},
            {key:'mass_g',label: 'Mass(g)', type:'number'},
            {key:'sin', label:'SIN',type:'barcode'},
            {key:'comment_field',label: 'comment', type:'text'},
            {key:'sample_type',label: 'Sample', type:'select', optionstable: 'sample_type'},
            {key:'size_class', label: 'Size Class', type: 'select', optionstable:'size_class'},
            {key:'global_id',label: null, type:null, keyfield: true},
            {key:'parent_id',label: null, type:null},
            {key:'set_num', label: null, type:'number'}
        ]
    },
    tableKeys: 
    {
        site_visit:['station','tributary','visit_date'],
        net_set:['station','tributary','visit_date', 'set_num'],
        catch:['station','tributary','visit_date', 'set_num','global_id'],
        tally:['station','tributary','visit_date', 'set_num','global_id'],
    },
    dataTables: [
        "site_visit",
        "net_set",
        "catch",
        "tally",
    ],
   
    views:[
        "catch"
    ],
    refTables:[
        "site",
         "tributary",
        "species",
        "size_class",
        "sample_type",
        "age",
        "hot_buttons",
        "gear",
        "ref_observer",
        "ref_net_gear"
     ],
    dataCache:{
        site_visit:183,
        net_set:183,
        catch:1,
        tally:1
    },      
}