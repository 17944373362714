import axios from 'axios'
import store from "../store"
import localforage from "localforage";
import { extendPrototype as startswith } from "localforage-startswith"; 
import { extendPrototype as remove } from "localforage-removeitems"
import data from '../plugins/data'

startswith(localforage)
remove(localforage)
const ACCURACY_THRESHOLD = 500;
axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT_URL;
// axios.defaults.headers.post["Content-Type"] =
//   "application/x-www-form-urlencoded";
axios.defaults.withCredentials = true;

export default {
async getApiData(holder,varName, providedendpoint=null, days=null) {
  //data is only ever retrieved if user is authenticated.
  if (store.getters['condition/authStatus'] == 'success'){
  let payload = {tableName:varName, tableType:holder};
  var endpoint;
     if(providedendpoint !== null) {
      endpoint = providedendpoint
    
     } else if (days !== null) {
      endpoint = `${store.getters['condition/dbName']}/${varName}/time_interval/${days}`;
     } else {
      endpoint = `${store.getters['condition/dbName']}/${varName}/`;
     }
     return axios
      .get(endpoint)
      .then(
        response =>
          {payload['records'] = response.data.sort(
            (a, b) => a.sequence - b.sequence
          )
          store.commit('data/refreshRecords', payload)
        } 
      )
      .catch(err => {
        console.log(err)
        //failed to retrieve data so localforage data is used
        this.replaceStoreWithLocalForage(payload)
      }
      )
    }
  },

  async updateStoredData() {
    //send current data
    await this.submitLocalData() //each susccessful submit causes the table submitted to refresh
    this.populateViews()// refresh data views

  },
  async delete(deleteInfo) {
    let endpoint
    console.log('deleting data from database')
    if ('key' in deleteInfo) {
      endpoint = `delete/${deleteInfo.tableName}/keys/${deleteInfo.key}/`
    } else {
      endpoint = `delete/${deleteInfo.tableName}/keys/global_id/`
    }
    return new Promise((resolve, reject) => {
      if (deleteInfo.payload.length) {
        console.log(deleteInfo.payload)
        axios
          .post(endpoint, JSON.stringify(deleteInfo.payload))
          .then(response => {
            if (response.status === 200) {
              let rowsDeleted = 0
              for (const i in response.data.message) {
                if (
                  response.data.status[i] !== 'error' &&
                  response.data.message[i].search('deleted') > -1
                ) {
                  rowsDeleted = rowsDeleted + 1
                }
              }
            }
            resolve();
          })
          .catch((err) => {
            console.log('error posting', err);
            reject()
        })
      } else {
      resolve();
      }
  });
  },
  async submit(submitInfo){
    //data is only ever submitted if user is authenticated.
  //if (store.getters['condition/authStatus'] == 'Success'){
    let endpoint = `${store.getters['condition/dbName']}/${submitInfo.table}/keys/${submitInfo.key}/`
    let payload = submitInfo.payload
    console.log(endpoint)
    console.log(payload)
    let rowStatus=[];
    console.log(store.getters['condition/authStatus'])
    return new Promise((resolve, reject) => {
        if ((submitInfo.payload.length) && (store.getters['condition/authStatus'] === 'success')){
        return axios
        .post(endpoint, submitInfo.payload)
        .then(response => {
            if (response.status === 200) {
               
                let rowCount = 0
                for (let i in response.data.message) {
                if (
                    response.data.status[i] != "error" &&
                    response.data.message[i].search("inserted") > -1
                ) {
                  let key =  `${store.getters['condition/dbName']}_${submitInfo.table}_${this.tableSpecificKey(submitInfo.table,submitInfo.payload[i])}`
           
                     rowStatus.push(key)
                     rowCount = rowCount+ 1;
                }
                
                }
                if (rowCount) {
                  this.rowsInserted = rowCount;
                  this.submitSuccess = true;
                  resolve(rowStatus)
                }
            } else {
                // TODO: failure toast
                console.log("failure toast, response code = " + response.status);
                reject(submitInfo)
               }
            })
            .catch(err => {
            console.log("error posting", err); 
              reject(submitInfo)
            });
        }
        this.rowsInserted = 0;
        resolve(rowStatus)
    })
},
replaceStoreWithLocalForage(payload) {
  //payload contains table and holder
  //delete from local
  this.clearStore()
  //populate store from localforage
  this.restoreFromLocalForage(payload)
  console.log(payload.tableName)
},
populateViews () {
    for (let view in store.state.datatables) {
        this.getApiData('Views',view)
    }
},
isValidTime(value) {
  try{
    new Date(value)
  } catch(err) {
    return false;
  }
},
newRecord(tableName) {
  if (tableName==='net_set'){
    return {
      station:store.getters['data/attribute']('current_site_visit').station,
      tributary:store.getters['data/attribute']('current_site_visit').tributary,
      visit_date:store.getters['data/attribute']('current_site_visit').visit_date,
      set_num: store.getters['data/attribute']('selectedSetNum'),
      parent_id: store.getters['data/attribute']('current_site_visit').global_id,
      gear: '',
      start_time:'',
      stop_time:'',
      start_latitude:'',
      stop_latitude:'',
      start_longitude:'',
      stop_longitude:'',
      flow_start:'',
      flow_stop:'',
      depth_start:'',
      depth_stop:'',
      comment:'',
      global_id:'',
      ts: this.getLocalTimestamp(new Date())
    }
  } if (tableName==='site_visit'){
    return {
      station:store.getters['data/attribute']('selectedStation'),
      tributary:store.getters['data/attribute']('selectedTributary'),
      visit_date:store.getters['data/attribute']('selectedVisitDate'),
      temperature_c:'',
      investigators:'',
      gear:'',
      comments:'',
      global_id:'',
      ts: this.getLocalTimestamp(new Date())
    }
  }
  if (tableName==='catch'){
    return {
      station:store.getters['data/getCurrentRecord']('net_set').station,
      tributary:store.getters['data/getCurrentRecord']('net_set').tributary,
      visit_date:store.getters['data/getCurrentRecord']('net_set').visit_date,
      set_num: store.getters['data/getCurrentRecord']('net_set').set_num,
      parent_id: store.getters['data/getCurrentRecord']('net_set').global_id,
      field_species:store.getters['data/attribute']('lastSpecies'),
      age:'',
      length:'',
      mass_g:'',
      size_class:'',
      sin:'',
      comment:'',
      sample_type:'',
      global_id:'',
      ts: this.getLocalTimestamp(new Date())
    }
  }
  if (tableName==='tally'){
    return {
      station:store.getters['data/getCurrentRecord']('net_set').station,
      tributary:store.getters['data/getCurrentRecord']('net_set').tributary,
      visit_date:store.getters['data/getCurrentRecord']('net_set').visit_date,
      set_num: store.getters['data/getCurrentRecord']('net_set').set_num,
      parent_id: store.getters['data/getCurrentRecord']('net_set').global_id,
      species:'',
      age:'',
      tally:'',
      comment:'',
      size_class:'',
      global_id:'',
      comment_field:'',
      ts: this.getLocalTimestamp(new Date())
    }
  }
},
// provideRecord(tableName) {
//   if (store.getters['data/tablePositions'](tableName) == -1) {
//     return this.newRecord(tableName);
//   } else {
//     return store.getters['data/getCurrentRecord'](tableName)
//     ;
//   }
// },
matched (v1, v2) {
  if ((v1 !== undefined) & (v2 !== undefined)) {
    return v1.toString() === v2.toString()
  } return false
},
valueLookup (value, field1, field2, arr) {
  const obj = arr.find(o => this.matched(o[field1], value))
  if (obj !== undefined) {
    return obj[field2]
  }
  return null
},

lookup (item, header) {
  if (header.type === 'select') {
    if (item[header.value] !== undefined) {
      const arr = store.getters['data/refTable'](header.optionstable)
      const obj = arr.find(o => o.code.toString() === item[header.value].toString())
      if (obj !== undefined) {
        if ('title' in obj) {
          return obj.title
        } else { return obj.code }
      }
    }
  }
  return item[header.value]
},
//update timer end fields for net set and update refresh the net_set records in store.
async updateRecord(tableName, global_id, fvArray) {
  let key = `${store.getters['condition/dbName']}_${tableName}_${global_id}`
    localforage.getItem(key)
    .then((record) => {
      fvArray.forEach((o) => {record[o.field] = o.value})
      localforage.setItem(key,record)
      .then((result) => {
        console.log(result)
        //localforage.getItem(key).then((result) => console.log(result))
        //store.commit('data/setSiteVisitRecord')
      });
    })
    .catch()
},
matchItem(targetItem, compareItem,keyFields){
  let match = true; //starts off true until proven otherwise
  match = keyFields.every((el) => this.doesMatch(targetItem,compareItem,el))
  return match
},
doesMatch(targetItem, compareItem, element){
  return targetItem[element] === compareItem[element];
},
//find the position of a record within an array of records
findPosition(records, searchField, searchValue) {
  let index = records.findIndex(
    (x) =>
    x[searchField] === searchValue || x[searchField] === Number(searchValue)
  );
  return index > -1 ? index : -1
},
//Async returns entire table data from local forage
async getForageRecords(table) {
  return new Promise((resolve, reject) => {
    let key = `${store.getters['condition/dbName']}_${table}`
    localforage.startsWith(key).then((result) => {
      let records = this.forceFieldTypes(table, Object.values(result));
      resolve(records)
    })
    .catch((err) => {
      reject(`${table}:${err}`)
    })
  });
},
attributeAsCSVValue(valuein){
  if ((valuein !== undefined) && (valuein !== null)) {
    return valuein.toString().replace(","," ")
  }
},
getObjectValues(obj,keys) {
  return keys.map((k) =>
  {
    return obj[k] ? this.attributeAsCSVValue(obj[k]) : 'NA';
  })
},
//converts records returned from local forage into a csv string
recordsToCSV(tableName,forageRecords) {
  let header = data.tableFields[tableName].map(field => {return field.key})  
  let dataString = forageRecords.map(it => {
      return this.getObjectValues(it,header).toString()
  }).join('\n');
 
  return header.toString() + "\n" + dataString
},
//Submits all local forage data to backend. 
  async submitLocalData() {
    for (let table in store.getters['data/dataTables']) {
        await this.getForageRecords(table) 
            .then((responseData)=>{ //read local forage records and submit
              let key = 'u';
              if (table === 'site_visit') {
                key = 'p';
              }
              this.submit({table:table, payload:responseData, key:key}) //standard key is u but  for site visit p is required
              .then((submitResult) => {
                submitResult;
                 //this.removeSyncedForage(submitResult)
                //submit was succeful so retreive relevant records
                this.getApiData('dataTables',table, null, data.dataCache['table'])
                //this.getApiData('dataTables',table, null,null)
              })
            })
            .catch((rejectedData) => {
                //something went wrong during submission
                console.log(rejectedData.table) //log the failure and move on with local data
            });
    }
  },

  storeUserSession(data) {
    localforage.setItem('user', data);
    console.log("Storing session")
  },
  removeUserSession(){
    localforage.removeItem('user')
  },
  //Returns time difference in seconds between two timestamps
  getTimeDiff(t1,t2){
    return Math.abs(t2-t1) / 1000 //timediff in seconds
  },
  //Returns date as string
  getLocalDate: function (e) {
    let ts = new Date(e);
    return `${ts.getFullYear()}-${String(ts.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(ts.getDate()).padStart(2, "0")}`;
  },
 //Returns timestamp as formatted string
  getLocalTimestamp: function (e) {
    let ts = new Date(e);
    return `${ts.getFullYear()}-${String(ts.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(ts.getDate()).padStart(2, "0")} ${String(
      ts.getHours()
    ).padStart(2, "0")}:${String(ts.getMinutes()).padStart(2, "0")}:${String(
      ts.getSeconds()
    ).padStart(2, "0")}`;
  },
  //Uses table unique key colums to create a unique id.
  //Uses global_id for data tables and code for reference tables
  tableSpecificKey(table, record) {
    if (data.refTables.includes(table)) {
      return record.code
    }else if (data.dataTables.includes(table)){
      return record.global_id
    }
  },
  async deleteRecord(payload) {
    //make the table specific keys
    let key = `${store.getters['condition/dbName']}_${payload.tableName}_${this.tableSpecificKey(payload.tableName,payload.record)}`
    //delete from local
    localforage.removeItem(key)
    .then(() => {
      //delete from backend if we can
      this.delete({tableName:payload.tableName, records:[payload.record]})
      .then(() => console.log("record deleted"))
      .catch(() => {
        let record = payload.record;
        record.tableName = payload.tableName
        localforage.setItem(`delete_${key}`,{tableName:payload.tableName, global_id:payload.record.global_id}); //put it somewhere to be deleted later
      })
      //refresh the store records
      if (payload.tableName === 'catch' || payload.tableName === 'tally') {
        store.commit('data/setNetSet');
      }
    })
  },
  //updates a record in store and local forage after updating mandatory fields.
  async saveRecord(table,record, pi = null){
    if(table === 'catch' && record.field_species != ''){
      store.commit('data/updateAttribute',{attribute:'lastSpecies',value:record.field_species})
    }
    record = this.addMandatory(table, record, pi)
    return new Promise((resolve, reject) => {
      this.writeRecordToLocalForage({tableName:table, record:JSON.parse(JSON.stringify(record))})
      .then(() => {
        resolve(record)})   
      .catch(() => {
        console.log(record)
        reject(record)
      })
    })
    
  },

  //populates mandatory fields for a record 
  addMandatory(table, record,pi){
    if (pi != null){
      record.parent_id = pi
    }
    record.ts = record.ts != undefined ? record.ts : this.getLocalTimestamp(new Date())
    record.created_ts = record.created_ts != undefined ? record.created_ts : this.getLocalTimestamp(new Date())
    record.editted_ts = this.getLocalTimestamp(new Date())
    record.global_id = (record.global_id === '' | record.global_id === undefined) ?
     this.makeGlobalId(table) :
     record.global_id
    return record
  },

  // saveLocalReferenceTable: async (table, record) => {
  //   await this.writeRecordToLocalForage(table, record)
  // },
  async restoreRefFromLocal(tableName) {
    this.restoreFromLocalForage({tableName:tableName, holder:'refTables'})
  },
  
  async restoreFromLocalForage(payload) {
    let key = `${store.getters['condition/dbName']}_${payload.tableName}`
    await localforage.startsWith(key).then((result) => {
      let records = this.forceFieldTypes(payload.tableName, Object.values(result));
      //an extra check to make sure site_visit records dont end up in site list
      if (payload.holder === 'refTables') {
        records = records.filter((el)=>
        el.code !== undefined)
      }
      store.commit("data/refreshRecords", {
        tableName: payload.tableName,
        records: records,
        tableType: payload.holder,
      });
    });
  },

  forceFieldTypes(tableName, records) {
    let header = data.tableFields[tableName];
    for (let item in records) {
      try {
        records[item] = this.forceFieldType(header, records[item]);
      } catch (err) {
        console.log(err);
      }
    }
    return records;
  },
  forceFieldType(header, record) {
    for (let a in record) {
      if ((record[a] !== undefined) && ((record[a] == null) || (record[a].length < 1))) {
        delete record[a];
      }
      else if ((header != null) && (
        header
          .filter(function (el) {
            return el["type"] === "number";
          })
          .map((a) => a.key)
          .includes(a)
      )) {
        record[a] = Number(record[a]);
      }
      else if ((header != null) && (
        header
          .filter(function (el) {
            return el["type"] === "boolean";
          })
          .map((a) => a.key)
          .includes(a)
      )) {
        record[a] = String(record[a]);
      }
    }
    return record;
  },

  //update store with ref tables in local forage
 async updateCurrentRefTables () {
  let tableList = data.refTables
  tableList.forEach((k, index) => 
    {index;
    this.restoreRefFromLocal(k);
    }
  );
 },

 //write a record to local forage.
 async writeRecordToLocalForage (payload) {
    let key = `${store.getters['condition/dbName']}_${payload.tableName}_${this.tableSpecificKey(payload.tableName,payload.record)}`
    //localforage.setItem(key, JSON.stringify(payload.record))
    return new Promise((resolve, reject) => {
      localforage.setItem(key, payload.record)
      .then(() => {
        resolve(payload.record)})   
      .catch(() => {
        console.log(payload.record)
        reject(payload.record)
      })
    });
 },
 //Sets all table data in store to empty arrays. 
 //also sets top level record to empty 
 // resets table data positions to -1
 clearStore(){
    let datatableList = store.getters['data/dataTables']
    store.commit('data/resetPrimary') //this resets the selectors
    for (let k in datatableList){
      store.commit('data/refreshRecords',{tableName:k,tableType:'dataTables',records:[]})
      store.commit('data/updateAttribute',{attribute:`current_${k}`,value:this.newRecord(k)})
      
    }
 },
 //generate a global id dependent on device code, tablename and timestamp
 makeGlobalId(table){
  return `${table}_${store.getters['condition/deviceCode']}_${Math.floor(Date.now() / 1000)}`
 },
 

//TODO implement
 allKeysPopulated(table,record) {
  table
  record
  return true
 },
 //populate all reference tables in the store data with reference table data from backend
 populateReferenceTables(){
    data.refTables.forEach(async key => {
    await this.getApiRefData(key)});
},
// populate a specific store reference table with data from the backend
getApiRefData: function(varName){
  //let endpoint = `${store.getters['condition/dbName']}/${varName}`;
  if (store.getters['condition/isLoggedIn']) {
     this.getApiData("refTables",varName);
  }
},
oldAndSynced(key, record) {
  if (key.includes('site_visit') || key.includes('net_set')){
    'keep last 6 months'
    return (new Date() - Date.parse(record.created_ts)) / 1000 > 15552000
  }else{
    'keep 1 week'
    return (new Date() - Date.parse(record.created_ts)) / 1000 > 604800
  }
},
//Remove items from local forage.
//all keys in removalkeys list will be removed.
removeSyncedForage(removalKeys) {
    //localforage.removeItems(removalKeys)
   removalKeys.map((key) => {
      localforage.getItem(key)
      .then((record) => {
        record['synced']= this.getLocalTimestamp(new Date())
        if (this.oldAndSynced(key,record) ) {
          localforage.removeItems([key])
        } else{
          localforage.setItem(key, record)
        }
      })
      .catch((err) => console.log(err))
      })
    },
//populate all local forage record from all datatables in store.
syncAllLocalForageFromStore(){
  data.dataTables.forEach(async key => {
    this.syncLocalForageFromStore({tableType:'dataTables',tableName:key})
    })
  },
  //write all record sfrom a specific table specified in payload to local forage.
  syncLocalForageFromStore(payload){
    if (payload.tableType == 'dataTables') {
      let records = store.getters['data/tableData'](payload.tableName).map(item => ({...item}))
      records.forEach(async record => {
          await this.writeRecordToLocalForage({tableName: payload.tableName, record:record})
          .then(() => {
            })   
          .catch(() => {
            console.log(payload.record)
            })
      }
    )
    } else if (payload.tableType == 'refTables') {
      let records = store.getters['data/refTable'](payload.tableName).map(item => ({...item}))
      records.forEach(async record => {
        this.writeRecordToLocalForage({tableName: payload.tableName, record:record})
        .then(() => {
          })   
        .catch(() => {
          console.log(record)

        })
        }
      )
    }
    },
//populate all local store datatables with records from backend
retrieveDataTables() {
  data.dataTables.forEach(async key => {
    this.getApiData('dataTables',key)
  })
},
//populate all store views with data from backend
refreshViews() {
  data.views.forEach(async key => {
    this.getApiData('views',key)
  })
},
//make sure all datatables, views, reference tables have a corresponding record in the vuex store
initializeData(){
  //reference tables
  data.refTables.forEach( async key => {
    store.commit('data/refreshRecords',{tableName:key,tableType:'refTables',records:[]})
  })
  data.dataTables.forEach( async key => {
    store.commit('data/refreshRecords',{tableName:key,tableType:'dataTables',records:[]})
  })
  data.views.forEach( async key => {
    store.commit('data/refreshRecords',{tableName:key,tableType:'views',records:[]})
  })
},
// attempts to pull data from specified database.
// If fails localforage data is moved to store.
// on success store and previously submitted forage records are updated
 refreshDataCache() {
    this.populateReferenceTables();
    this.retrieveDataTables();
    this.refreshViews()

 },
 //GPS functions
 startWatch: function () {
  let geoOptions = {
    enableHighAccuracy: true,
    maximumAge: 15000,
    timeout: 15000,
  };
  navigator.geolocation.getCurrentPosition(
    this.updateLocation,
    this.locationError,
    geoOptions
  );
  this.watchID = navigator.geolocation.watchPosition(
    this.updateLocation,
    this.locationError,
    geoOptions
  );
},
stopWatch: function () {
  navigator.geolocation.clearWatch(this.watchID);
},
turnGPSOn: function () {
  if (!store.state.gpsOn) {
    this.startWatch();
    store.commit("condition/setGPSState", false);
  }
},
turnGPSOff: function () {
  if (store.state.gpsOn) {
    this.stopWatch();
    store.commit("condition/setGPSState", false);
  }
},
gpsToggle: function () {
  if (store.state.gpsOn) {
    this.startWatch();
  } else {
    this.stopWatch();
  }
},
getLatitude() {
  if (store.getters['condition/coordinates'] != null) {
    return store.getters['condition/coordinates'].latitude;
  } else {
    return 0.0;
  }
},
getLongitude() {
  if (store.getters['condition/coordinates'] != null) {
    return store.getters['condition/coordinates'].longitude;
  } else {
    return 0.0;
  }
},
updateLocation: function (position) {
  if (position.coords.accuracy < ACCURACY_THRESHOLD) {
    store.commit("condition/setCoordinates", position.coords);
  }
},
locationError: function (err) {
  console.log("location error: " + err.message);
},
asBoolean(strvalue) {
  if (strvalue == "true") {
    return true;
  }
  return false;
},
asString(boolval) {
  if (boolval) {
    return "true";
  }
  return "false";
},

}
