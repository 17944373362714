<template>
  <v-container fluid>
   <SiteVisit :selectedRecord="site_visit" :refTables="refTables" v-on="handler" ref="site_visit"></SiteVisit> 
   <v-form v-if="site_visit.global_id !== undefined" >
 <v-radio-group v-if="site_visit_valid"
  v-model="set_num" inline>
  <v-radio label="One" value=1></v-radio>
  <v-radio label="Two" value=2></v-radio>
  <v-radio label="Three" value=3></v-radio>
  <v-radio label="Four" value=4></v-radio>
</v-radio-group>
 <NetSet v-if ="set_num > 0 && site_visit.global_id !== ''" :selected_record = "NetSetRecord"></NetSet> 
<v-tabs v-model="tab"
align-tabs="center"
color="blue">
  <v-tab value="catch">Catch</v-tab>
  <v-tab value="tally">Tally</v-tab>
    </v-tabs>
    <v-window v-model="tab">
      <v-window-item
        v-for="page in pages"
        :key="page"
        :value="page"
      >
        <v-container fluid>
         <TableData v-if ="NetSetRecord !== undefined && NetSetRecord.stop_time !== undefined && NetSetRecord.stop_time !== ''"
         :tableName="page"
         :value="tabTable(page)"
         :fields="tableFields(page)"
         :refTables="refTables"
         v-on="handler"
         :table_global_id="table_global_id_position"
         ></TableData>
        </v-container>
      </v-window-item>
    </v-window>
   
</v-form>
  </v-container>
</template>

<script>
import support from "../plugins/support";
import data from "../plugins/data";
import SiteVisit from "@/components/SiteVisitEntry.vue"
import NetSet from "@/components/NetSet.vue"
import TableData from "@/components/TableData.vue"
export default {
  name: "DataCollect",

  components: {
    SiteVisit,
     NetSet,
     TableData
},
  props: {
    datatype: String,
  },
  created() {
    
  },
  data() {
    return {
      maxCount:30, 
      mounted: false,
      table_global_id_position: null,
      handler: {
        request_new_record: this.newTableRecordRequest,
        request_hot_record: this.newTableHotRecordRequest,
        site_visit_set: this.refreshSiteVisitRecord
      },
      tab: null,
      required: [(v) => !!v || "Required"],
      pages: ["catch", "tally"]
    };
  },
  computed: {
    site_visit_valid () {
      if (this.mounted) {
      return this.$refs.site_visit.formValid;
      } else {
        return false;
      }
    },
    refTablesSet() {
      console.log(this.refTables['site'])
      console.log(this.refTables['tributary'])
      return this.refTables['site'].length > -1 && this.refTables['tributary'].length > -1
    },
    tallyFields() {
      return data.tableFields['tally'];
    },
    catchFields() {
     return data.tableFields['catch'];
    },
    site_visit_records() {
      return this.$store.getters['data/tableData']('site_visit')
    },
    site_visit() {
      return this.$store.getters['data/getCurrentRecord']('site_visit')
      //return this.$store.getters['data/attribute']('siteVisitRecord')
    },
    refTables() {
    return this.$store.getters['data/refTables']
   },
   set_num:{
    get(){return this.$store.getters['data/attribute']('selectedSetNum')},
    set(value){
      this.$store.commit('data/updateAttribute',{attribute:'selectedSetNum', value:value})
    },
  },
    catchTally() {
      let tally = {}
      this.catchRecords.forEach(record => {
        let key = `${record.field_species}_${record.age}_${record.size_class}`
        tally[key] = tally[key] ? tally[key] + 1 : 1
      })

      return this.removeNumbersSmallerThan(this.maxCount,tally)
   },
   station() {
     return this.$store.getters['data/attribute']('selectedStation')},

   tributary(){return this.$store.getters['data/attribute']('selectedTributary')},
     
    visit_date() {return this.$store.getters['data/attribute']('selectedVisitDate')},
     
    NetSetRecords() {
      return this.$store.getters['data/tableData']('net_set');
      //return this.$store.getters['data/getCurrentRecord']['net_set']
    },
    NetSetRecord() {
      return this.$store.getters['data/getCurrentRecord']('net_set');
      //return this.$store.getters['data/getCurrentRecord']['net_set']
    },
    tallyRecords() {
      return this.$store.getters['data/tableData']('tally').filter((el) =>
    el.parent_id === this.NetSetRecord.global_id)
    },
    catchRecords() {
      return this.$store.getters['data/tableData']('catch').filter((el) =>
    el.parent_id === this.NetSetRecord.global_id)
    },   
    dbName() {
      return this.$store.getters.dbName;
    },
    databaseSet() {
      return !(
        this.dbName == undefined ||
        this.dbName == null ||
        this.dbName == ""
      );
    },
  },
  async mounted() {
    support.restoreFromLocalForage({tableName:'site_visit',holder:'dataTables'})
    this.$watch(
      "set_num",
      () => {
        support.saveRecord('site_visit', this.$store.getters['data/getCurrentRecord']('site_visit'))
        this.$store.commit('data/setNetSet')
        // let p = support.findPosition(
        //   this.$store.getters['data/tableData']('net_set'), 'set_num', this.set_num)
        // this.$store.commit("data/updateAttribute", {attribute:'current_net_set',value:this.$store.getters['data/tableData']('net_set')[p]})
      },
      { deep: true}
      );
    this.$watch(
      "dbName",
      () => {
        this.kickback();
      },
      { deep: true }
    );
   this.mounted = true;
  },
  methods: {
    removeNumbersSmallerThan(num, obj) {
    for (var property in obj) {
        if (obj[property] < num) {
            delete obj[property];
        }
    }
    return obj
},
    tabTable(tab){
      if (tab == 'tally'){
        return this.tallyRecords
      }else{
        return this.catchRecords
      }
    },
    newTableRecordRequest(tableName) {
      console.log("new record request")
      let record = support.newRecord(tableName)
      support.saveRecord(tableName, record, this.NetSetRecord.global_id, -1) 
      .then(() => {
        this.$store.commit('data/setNetSet') //refresh the store records so they match lf.
      });
    },
    //a new hot record comes with some data attributes
    newTableHotRecordRequest(info) {
      console.log("new hot record request")
      let record = support.newRecord(info.tableName)
      if (info.tableName === 'catch'){
        record.field_species = info.species
        record.age = info.age
      }
      if (info.tableName === 'tally'){
        record.species = info.species
        record.age = info.age
      }
      support.saveRecord(info.tableName, record, this.NetSetRecord.global_id, -1) 
      .then((returned) => {
        //this.$store.commit('data/setNetSet') //refresh the store records so they match lf.
        this.table_global_id_position = returned.global_id;
        this.$store.commit('data/refreshChildTables');
      });
     
    },
    refreshSiteVisitRecord() {
      console.log(this.site_visit_records)
      console.log("setting site visit record")
      //find the position in site_visit store table
      let position = this.site_visit_records.findIndex(
              (el) =>
              el.station === this.station &&
          el.tributary === this.tributary &&
          Date.parse(el.visit_date) === Date.parse(this.visit_date)
            );
      if(position > -1){
              this.$store.commit('data/updateAttribute',{attribute:'current_site_visit', value:this.site_visit_records[position]})
      }
    
    },
    tableFields(tab){
      if (tab == 'tally'){
        return this.tallyFields
      }else{
        return this.catchFields
      }
    },
    kickback() {
      if (this.needsDb) {
        this.$router.push({ name: "home" });
      }
    },
  },
};
</script>
