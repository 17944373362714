<template>
  <v-container fluid class="fill-width">
    <v-row>
   <v-col cols="9">
      <EditableTable
        ref="mainTable"
        :tableName="tableName"
        :value="tableItems"
        :fields="headers"
        :showAdd="showAdd"
        sortField="created_ts"
        v-on="handler"
      >

    </EditableTable>
  </v-col >
  <v-col cols="3" align-self="end">

    <div v-for="(btn, i) in refTables['hot_buttons']" :key='i'>
        <v-btn 
        class="mt-2"
        size="small"
        dark 
        color="blue"
        @click="addHotRecord(btn.species, btn.age)"
        >
        {{ btn.code }}
        </v-btn>
</div>
  </v-col>
</v-row>
    <v-tooltip bottom>
    <template v-slot:activator="{ attrs }">
    <v-fab-transition>
              <v-btn
                color="pink"
                dark
                absolute
                bottom
                right
                fab
                v-bind="attrs"
          @click="addRow">
              
                <v-icon>mdi-plus</v-icon>
       </v-btn>
       </v-fab-transition>
       </template>
       </v-tooltip>
  </v-container>
</template>

<script>
//import TableEditCell from '@/components/v3TableEditCell.vue'
//import RecordEditMenu from '@/components/RecordEditDialog.vue'
//import v3TableEditCell from '@/components/v3TableEditCell.vue'
import EditableTable from './EditableTable.vue'

//import IntegerArrayAsStringCell from '@/components/IntegerArrayAsStringCell.vue'
import support from '../plugins/support'
export default {
  name: 'TableData',
  components: { 
    EditableTable
  },
  showAdd:false,

  props: {
    value: Array,
    fields: Array,
    parent_id: String,
    tableName: String,
    showAll: Boolean,
    disabled: Boolean,
    table_global_id:{
      type: String,
      default: null
    },
    formatter:{
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tableItems: this.value.map((item, index) => ({ ...item, delete: false, selected: this.currentSelection(index) })),
      headers: this.fields.map(item => ({
        title: item.label,
        value: item.key,
        type: item.type,
        optionstable: item.optionstable,
        align: 'left',
        width: item.width,
        disabled: item.disabled,
        required: this.isRequired(item)
      })).filter((el) => el.label !== null),
      parentField: 'parent_id',
      addRecordText: 'add a ' + this.tableName + ' record',
      handler: {
        add_record: this.addTableItem,
        submitItem: this.submit,
        delete: this.deleteRow
      },

    }
  },
  created () {
    this.$watch(
      'value', () => {
           this.tableItems = this.value.map((item, index) => ({ ...item, delete: false, selected: this.currentSelection(index) }))
          },
      { deep: true }
    )
    // this.$watch(
    //   'hotGlobal', () => {
    //        console.log(this.hotGlobal)
    //       },
    //   { deep: true }
    // )
  },
 computed: {
  //lastPage(){return Math.ceil(this.tableItems.length / this.itemsPerPage)},
  refTables() { return this.$store.getters['data/refTables']}
 },
  methods: {
   
    inMarkObject(record){
      let filtered = []
      try{
        let key = `${record.field_species}_${record.age}_${record.size_class}`
        filtered = Object.keys(this.formatter).filter((el)=>
        el === key);
      }catch{
        console.log("field_species not found")
      }
      return filtered.length > 0;
    },
   
    submit (saveData) { 
      let record = saveData.record;
      if (this.tableName === 'catch') {
        record = this.updateItem(saveData.record); //auto populate size class
      }
      support.saveRecord( //writes to local forage, on return we update the store
                  this.tableName, record)
            .then(() => {
              this.$store.commit('data/refreshChildTables') 
              })
      
      //this.$emit('submit', { payload: [item], table: this.tableName })
    },
    
    isFirstRecord (index) {
      return index === 0
    },
    currentSelection (index) {
      return this.$store.getters['data/tableData'](this.tableName)[index] === this.$store.getters['data/getCurrentRecord'](this.tableName)
    },
    selectValue (value) {
      if (value !== undefined) {
        return value.toString()
      }
      return ''
    },

    isSelected (item, selectedItem) {
      return item[this.keyField] === selectedItem[this.keyField]
    },
    deleteRow (deleteInfo) { 
      support.deleteRecord({ tableName: this.tableName, record: deleteInfo.record })
      .then(()=> 
        {
          //refresh the store records
          console.log("record deleted")
        })
    },

    copyRecord (record) {
      // const info = { table: this.tableName, record: record }
      this.$emit('copy_record', record)
    },
    recordSelected (record) {
      if (record.selected) {
        this.tableItems = this.value.map((item) => ({ ...item, delete: false, selected: this.isSelected(item, record) }))
        this.$emit('record_selected', record)
      }
    },
    addRow () {
      this.$emit('request_new_record', this.tableName)
    },
    addHotRecord(species, age){
      this.$emit('request_hot_record',{tableName:this.tableName,species:species,age:age})
    },
    isRequired (item) {
      if ('required' in item) {
        return item.required
      } else {
        return false
      }
    },
    handleClick(i) {
      console.log(i)
    },
    updateItem(record) {
      if (this.tableName === "catch"){
        record.size_class = this.findSizeClass(record.length)
      }
      return record;
    },

    findSizeClass(l) {
      if (l !== undefined){
         let p = this.refTables['size_class'].filter((el) => 
          el.min_length_mm <= l && el.max_length_mm >= l) 
          return p.length > 0 ? p[0]['code'] : 'n/data'
      }
      return 'n/data';
    }
   
  }
}
</script>
<style>
.btn {
  margin-top:10px;
}
</style>